import React, { useState } from 'react';
import './App.css';
import Login, { AuthenticationInformation } from './Login';
import Schedule, { BookingInformation, BookingState } from './Schedule';
import BookingProcess from './BookingProcess';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Room from './Business/Room';
import { Button } from 'react-bootstrap';

const authCookieName = 'authenticationInformation';

function App() {
  let localAuthenticationAsString = localStorage.getItem(authCookieName);
  let localAuthentication = localAuthenticationAsString ? JSON.parse(localAuthenticationAsString) as AuthenticationInformation : undefined;

  const [currentRoom, setCurrentRoom] = useState<Room | undefined>(undefined);
  const [currentHour, setCurrentHour] = useState<number>(-1);
  const [authenticationInformation, setAuthenticationInformation] = useState<AuthenticationInformation | undefined>(localAuthentication);

  const onBookingSelected = (room: Room, hour: number, bookingInformation: BookingInformation) => {
    console.log("Selected: %s / %s (%s)", room.name, hour, bookingInformation.state);

    if (authenticationInformation && bookingInformation.state === BookingState.None) {
      setCurrentRoom(room);
      setCurrentHour(hour);
    }
  }

  return (
    <div className="App">
      <Container>
        <Row className="App-header mb-3">
          <Col><h1>COLA Day - Booking</h1></Col>
        </Row>
        <Row className="mb-3">
          <Col xs={4}>
            <h2>Account</h2>
            {
              authenticationInformation ? 
              <>
                <p>Your are authenticated. Welcome, {authenticationInformation.name}! <small><Button variant="secondary" onClick={() => {setAuthenticationInformation(undefined); localStorage.removeItem(authCookieName)} }>Log out</Button></small></p>
                <p>Click on an available room to book it.</p>
              </>
              :
              <>
                <p>Please login to book a room.</p>
                <Login onSuccess={ authenticationInformation => { localStorage.setItem(authCookieName, JSON.stringify(authenticationInformation)); setAuthenticationInformation(authenticationInformation); }}></Login>
              </>
            }
            
          </Col>
        </Row>
        <Row>
          {
            (currentRoom && authenticationInformation) ? 
            <BookingProcess room={currentRoom} hour={currentHour} idToken={authenticationInformation.idToken} userId={authenticationInformation.id} />
            : 
            <></>
          }          
        </Row>
        <Schedule onSelected={onBookingSelected} userId={authenticationInformation ? authenticationInformation.id : undefined} />
      </Container>
    </div>
  );
}

export default App;

import Room from './Room'

class RoomRepository {
    private static rooms: Room[] = RoomRepository.loadRooms();

    private static loadRooms(): Room[] {
        let formatNumber = (value: number): string => value.toFixed().padStart(2, "0");
        let partners = [{
            name: "Coke",
            enumStartIndex: 1
        },
        {
            name: "Pepsi",
            enumStartIndex: 11
        }
        ];

        let roomList = partners.map(partner =>
            Array.from(Array(10).keys())
                .map(_ => new Room(
                    partner.name[0] + formatNumber(_ + 1),
                    _ + partner.enumStartIndex,
                    partner.name + " Room #" + formatNumber(_ + 1),
                    partner.name,
                    _ + 1)))
            .flat();

        return roomList;
    }

    private filter(predicate: (value: Room) => boolean): Room | undefined {
        let matchingRooms = RoomRepository.rooms.filter((value, index, array) => predicate(value));

        if (matchingRooms.length === 0) {
            return undefined;
        }
        else {
            return matchingRooms[0];
        }
    }

    getByEnumIndex(enumIndex: number): Room | undefined {
        return this.filter(room => room.enumIndex === enumIndex);
    }

    getById(id: string): Room | undefined {
        return this.filter(room => room.id === id);
    }

    getAll(): ReadonlyArray<Room> {
        return RoomRepository.rooms;
    }

    requestBooking(room: Room, hour: number, userId: string) {

    }
}

const roomRepository = new RoomRepository();
export default roomRepository;

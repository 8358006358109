import React, { useState, useEffect } from 'react';
import './Schedule.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import Room from './Business/Room';
import RequestBookingCommand from './Business/RequestBookingCommand';
import blockchain from './Blockchain/Blockchain';
import './BookingProcess.css';
import tickImage from './tick.jpg';
import spinningWheelImage from './spinning.webp';

interface BookingProcessProps {
    room: Room | undefined,
    hour: number,
    idToken: string,
    userId: string
}

const client = new ApolloClient({
    uri: 'https://api.thegraph.com/subgraphs/name/klejeune/home-project',
    cache: new InMemoryCache()
});

const gqlQuery = gql`{
    roomBookingSchedules(first: 5) {
      roomId
      userId
      hour
    }
    bookingCounts(first: 5) {
      booked
      available
    }
  }`

async function createTransaction(room: Room, hour: number, idToken: string): Promise<string> {
    let command = new RequestBookingCommand({
        room: room,
        hour: hour,
        idToken: idToken
    });

    var result = await command.execute();

    console.log("Transactionhash: " + result.transactionHash);

    return result.transactionHash;
}

function formatHash(hash: string): string {
    return hash.substr(0, 6) + "..." + hash.substr(hash.length - 5);
}

function BookingProcess(props: BookingProcessProps) {
    enum Step {
        UserConfirmation,
        TransactionCreation,
        TransactionProcessing,
        SubgraphUpdating,
        Done
    };

    const [currentStep, setCurrentStep] = useState(Step.UserConfirmation);
    const [transactionHash, setTransactionHash] = useState("");
    const [show, setShow] = useState(props.room !== undefined);

    console.log("Room : %s / SHOW: %s", JSON.stringify(props.room), show);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        switch (currentStep) {
            case Step.UserConfirmation:
                // nothing to do
                break;
            case Step.TransactionCreation:
                // We call the Azure Function to create the transaction
                (async () => {
                    let transactionResult = await createTransaction(props.room as Room, props.hour, props.idToken);
                    setTransactionHash(transactionResult);
                    setCurrentStep(Step.TransactionProcessing);
                })();
                break;
            case Step.TransactionProcessing:
                // We wait for the transaction to complete
                (async () => {
                    await blockchain.waitForTransaction(transactionHash);
                    setCurrentStep(Step.SubgraphUpdating);
                })();
                break;
            case Step.SubgraphUpdating:
                // We wait for the data to show in the subgraph
                setTimeout(() => setCurrentStep(Step.Done), 5000);
                break;
            case Step.Done:
                // everything is done
                break;
        }
    }, [currentStep]);

    useEffect(() => { setShow(true) }, []);

    const renderStepIcon = (step: Step) => {
        if (currentStep === step && step !== Step.Done) {
            return <img src={spinningWheelImage} alt="loading icon" width="18" />
        }
        else if (currentStep > step || currentStep === Step.Done) {
            return <img src={tickImage} alt="ok icon" width="18" />
        }
        else {
            return <></>
        }
    }

    if (props.room) {
        return (
            <>
                <Modal show={show} onHide={handleClose} dialogClassName="booking-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Book a room</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="Schedule">
                            <h2>{props.room.name}: {props.hour}h - {props.hour + 1}h</h2>
                            {
                                currentStep === Step.UserConfirmation ?
                                        <div>
                                            <p>Are you sure you want to book this room?</p>
                                            <ul>
                                                <li>Room "{props.room.name}"</li>
                                                <li>from {props.hour}h to {props.hour + 1}h</li>
                                            </ul>
                                            <div className="text-center">
                                                <Button variant="primary" onClick={() => setCurrentStep(Step.TransactionCreation)}>Confirm</Button>
                                            </div>                                            
                                        </div>
                                        :
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>{renderStepIcon(Step.TransactionCreation)}</td>
                                                    <td>
                                                        1. Creating blockchain transaction...                                                        
                                                    </td>
                                                    <td>{ transactionHash ? <a href={"https://ropsten.etherscan.io/tx/" + transactionHash} target="_blank">Transaction { formatHash(transactionHash) }</a> : <></> }</td>
                                                </tr>
                                                <tr>
                                                    <td>{renderStepIcon(Step.TransactionProcessing)}</td>
                                                    <td>2. Waiting for the transaction to complete...</td>
                                                    <td><strong>{ currentStep > Step.TransactionProcessing ? <>The transaction has been processed!</> : <></> }</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{renderStepIcon(Step.SubgraphUpdating)}</td>
                                                    <td>3. Waiting for the schedule content to update...</td>
                                                    <td><strong>{ currentStep > Step.SubgraphUpdating ? <>The schedule has been updated!</> : <></> }</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{renderStepIcon(Step.Done)}</td>
                                                    <td>4. Your booking is confirmed</td>
                                                    <td><strong>{ currentStep >= Step.Done ? <>Thank you!</> : <></> }</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                            }

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            currentStep === Step.Done ? 
                                <Button variant="secondary" onClick={handleClose}>Close</Button>
                                :
                                <></>
                        }
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
    else {
        return (<></>);
    }
}

export default BookingProcess;

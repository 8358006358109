import React, { useEffect, useState } from 'react';
import './Schedule.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import roomRepository from './Business/RoomRepository';
import Room from './Business/Room';

const client = new ApolloClient({
    uri: 'https://api.thegraph.com/subgraphs/name/klejeune/home-project',
    cache: new InMemoryCache()
});

const gqlQuery = gql`{
    roomBookingSchedules(first: 480) {
      roomId
      userId
      userName
      hour
    }
    bookingCounts(first: 1) {
      booked
      available
    }
  }`

interface ScheduleProps {
    onSelected: (room: Room, hour: number, bookingInformation: BookingInformation) => void;
    userId: string | undefined
}

export interface BookingInformation {
    state: BookingState;
    userId: string | undefined;
    userName: string | undefined;
}

export enum BookingState {
    None,
    Booked,
    BookedByUser
}

function Schedule(props: ScheduleProps) {
    const [bookings, setBookings] = useState<any>(undefined);

    const refreshBookings = async () => {
        let result = await client.query({
            query: gqlQuery
        });

        let bookings = result.data;

        console.log(bookings);
        setBookings(bookings);

        setTimeout(() => refreshBookings(), 5000);
    }

    const getBookingInformation = (room: Room, hour: number) => {
        if (!bookings) {
            return { state: BookingState.None, userId: undefined, userName: undefined };
        }

        var booking = bookings.roomBookingSchedules.filter((_: any) => _.roomId === room.id && _.hour === hour);

        if (booking.length >= 1 && booking[0].userId === props.userId) {
            console.log("booking found: %s", JSON.stringify(booking[0]));
            return { state: BookingState.BookedByUser, userId: booking[0].userId, userName: booking[0].userName };
        }
        else if (booking.length >= 1) {
            console.log("booking found: %s", JSON.stringify(booking[0]));
            return { state: BookingState.Booked, userId: booking[0].userId, userName: booking[0].userName };
        }
        else {
            return { state: BookingState.None, userId: undefined, userName: undefined };
        }
    }

    const getCellClassName = (bookingInformation: BookingInformation) => {
        switch (bookingInformation.state) {
            case BookingState.Booked:
                return " booked-cell";
            case BookingState.BookedByUser:
                return " booked-by-user-cell";
            case BookingState.None:
                return "";
            default:
                return "";
        }
    }

    const renderTooltip = (text: string, props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    );

    useEffect(() => {
        refreshBookings();
    }, [bookings]);

    const rooms = roomRepository.getAll();
    const hours = Array.from(Array(24).keys());

    const rows = rooms.map((room, roomIndex) =>
        <>
            <Row key={roomIndex} className="mb-1">
                <Col xs={3} className="text-right">{room.name}</Col>
                <Col xs={9}>
                    <table>
                        <tbody>
                            <tr>
                                {hours.map((hour, hourIndex) => {
                                    let bookingInformation = getBookingInformation(room, hour);
                                    return <td
                                        key={hourIndex}
                                        onClick={(mouseEvent: React.MouseEvent<HTMLTableDataCellElement>) => props.onSelected(room, hour, bookingInformation)}
                                        className={"hour-cell" + getCellClassName(bookingInformation)}
                                    >
                                        {
                                            bookingInformation.state === BookingState.Booked ?
                                                <OverlayTrigger placement="bottom" delay={{ show: 20, hide: 400 }} overlay={(props: any) => renderTooltip("This room has been booked by " + bookingInformation.userName + ".", props)}>
                                                    <span>{hour}h</span>
                                                </OverlayTrigger>
                                                : 
                                                bookingInformation.state === BookingState.BookedByUser ? 
                                                <OverlayTrigger placement="bottom" delay={{ show: 20, hide: 400 }} overlay={(props: any) => renderTooltip("You have booked this room. Click on it to cancel the booking.", props)}>
                                                    <span>{hour}h</span>
                                                </OverlayTrigger>
                                                :
                                                <span>{hour}h</span>
                                        }

                                    </td>
                                }
                                )}
                            </tr>
                        </tbody>
                    </table>
                </Col>
            </Row>
        </>)

    return (
        <div className="Schedule">
            <h2>Schedule</h2>
            <div className="mb-5">
            {rows}
            </div>
            <p className="text-right"><table><tbody><tr><td className="legend-cell booked-by-user-cell">Room booked by myself</td><td className="legend-cell booked-cell">Room booked by someone else</td><td className="legend-cell">Room not booked yet</td></tr></tbody></table></p>
            {
                bookings ?
                    <p><small>{bookings.bookingCounts[0].booked}/{bookings.bookingCounts[0].available} room have already been booked.</small></p>
                    :
                    <></>
            }

        </div>
    );
}

export default Schedule;

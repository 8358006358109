import React from 'react';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';

export interface AuthenticationInformation {
    idToken: string;
    id: string;
    name: string;
}

interface LoginProps {
    onSuccess: (info: AuthenticationInformation) => void
}

function Login(props: LoginProps) {
    const onSuccess = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        console.log(response);
    
        let castResponse = response as GoogleLoginResponse;
        if (castResponse.tokenId) {
            let authenticationInformation = {
                idToken: castResponse.tokenId,
                id: castResponse.profileObj.googleId,
                name: castResponse.profileObj.name
            }

            props.onSuccess(authenticationInformation);
        }
    }
    
    const onFailure = (error: any) => {
        console.log(error);
    }
    
    return (
        <GoogleLogin
            clientId="780038152938-1jqi5f8dutdrd24pg22ui3qsjc0hqef8.apps.googleusercontent.com"
            buttonText="Login"
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={'single_host_origin'}
        />
    );
}

export default Login;

import Room from './Room'

export interface RequestBookingCommandParameters {
    room: Room;
    hour: number;
    idToken: string;
}

export interface RequestBookingCommandResult {
    transactionHash: string;
}

class RequestBookingCommand {
    private _parameters: RequestBookingCommandParameters;

    public get parameters() { return this._parameters; }

    constructor(parameters: RequestBookingCommandParameters) {
        this._parameters = parameters;
    }

    async execute(): Promise<RequestBookingCommandResult> {
        let result = await fetch('https://booking-manager.azurewebsites.net/api/bookRoom?code=k1o0WObDGmPpRaTBl6hPu6sLIwLxukvH9Db2sRQHoUhu8UerYyVvoQ==', {
            method:'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                room: this.parameters.room.enumIndex,
                hour: this.parameters.hour,
                idToken: this.parameters.idToken,
            })
        })
        .then(response => response.json())

        return result;
    }
}

export default RequestBookingCommand;
class Room {
    id: string;
    enumIndex: number;
    name: string;
    partner: string;
    partnerIndex: number;

    constructor(id: string, enumIndex: number, name: string, partner: string, partnerIndex: number) {
        this.id = id;
        this.enumIndex = enumIndex;
        this.name = name;
        this.partner = partner;
        this.partnerIndex = partnerIndex;
    }
}

export default Room;

import { ethers } from 'ethers';

class Blockchain {
    private _provider: ethers.providers.JsonRpcProvider;

    constructor() {
        const rpcURL = "https://ropsten.infura.io/v3/554c93ad6b0b4e72af09525b7da665a6";

        this._provider = new ethers.providers.JsonRpcProvider(rpcURL);
    }

    async waitForTransaction(transactionHash: string): Promise<void> {
        await this._provider.waitForTransaction(transactionHash);
    }
}

const blockchain = new Blockchain();
export default blockchain;